<template>
  <v-container>
    <v-card>
      <v-toolbar
        dark
        dense
        color="red"
      >
        {{ idusuariosweb }}
        <v-col cols="1">
          <v-avatar
            tile
            dense
            size="55"
            color="red lighten-2"
            class="avatar rounded-lg"
          >
            <v-icon
              color="white"
              x-large
            >
              mdi-file-edit
            </v-icon>
          </v-avatar>
        </v-col>
        <h3> Actividades</h3>
        <v-spacer />
        <v-col cols="3">
          <v-text-field
            v-model="search"
            class="mx-2"
            solo-inverted
            dense
            color="red"
            append-icon="mdi-magnify"
            label="Filtrar"
            single-line
            hide-details
          />
        </v-col>
        <!-- BOTON DE CONSULTAR -->
        <v-btn
          small
          class="ma-4"
          color="success"
          @click="consultar"
        >
          Consultar <v-icon>mdi-update</v-icon>
        </v-btn>

        <!-- NUEVA OPORTUNIDAD -->
        <v-btn
          align="right"
          color="orange"
          dark
          small
          class="ma-4"
          @click.stop="actividad"
        >
          Nuevo <v-icon>mdi-pencil</v-icon>
        </v-btn>

        <!-- :to="{ name: 'actividad' }" -->


        <!-- EXCEL -->
        <!-- <v-btn
          align="right"
          class="ma-4"
          small
          color="green"
          dark
        >
          <download-excel
            :data="actividades"
            :fields="json_fields"
            worksheet="My Worksheet"
            name="CatActividades.xls"
          >
            Excel
            <v-icon>mdi-download</v-icon>
          </download-excel>
        </v-btn> -->
      </v-toolbar>
    </v-card>

    <v-row>
      <v-col>
        <v-card elevation="10">
          <!-- <v-subheader class="">
            <h2 class="ma-4 pt-2 red--text">
              Actividades
            </h2>
            <v-spacer />
          </v-subheader> -->

          <v-data-table
            :loading="loading"
            heigth="400"
            dense
            :headers="headers"
            :items="actividades"
            :search="search"
          >
            <template v-slot:item="{ item }">
              <tr @dblclick="editar(item)">
                <td class="red--text">
                  {{ item.idcrm }}
                </td>
                <td>{{ item.nomcont }}</td>
                <td>{{ item.empresa }}</td>
                <td>{{ item.tipo }}</td>
                <td>{{ item.obs }}</td>
                <td>{{ item.fecha }}</td>
                <td>{{ item.hora }}</td>
                <td>{{ item.idorden }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters} from 'vuex'
// import actividad from "@/views/crm/actividades/actividad"
export default {
  data () {
    return {
      drawer: false,
      loading : true,
      // FILTROS
      idusuariosweb:0,
      // idusuariosweb:0,
      idcontacto: 0,
      idproyecto:0,
      fecha1: (new Date((Date.now()-30) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      fecha2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      search: '',
      actividades: [],
      headers: [
        { text: 'Id', value: 'idcrm' },
        { text: 'Contacto', value: 'idcontacto' },
        { text: 'Empresa', value: 'empresa'},
        { text: 'tipo', value: 'tipo' },
        { text: 'Actividad Realizada', value: 'obs' },

        { text: 'Fecha', value: 'fecha' },
        { text: 'Hora', value: 'hora' },
        { text: 'Orden de Servicio', value: 'idorden' },
      ],
      json_fields: {
        Id: "idcrm",
        Contacto: "idcontacto",
        Cliente: "numcli",
        Tipo: "tipo",
        Descripcion: "obs",
        Fecha: "fecha",
        Hora: "hora",
        Orden: "idorden",
      },
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      json_data: [],
    }
  },

  computed: {
    ...mapGetters('login',['getdatosUsuario']),
    ...mapGetters('controles',['getDrawer2']),

  },

  watch: {

    getDrawer2(){
      console.log("getDrawer2", this.getDrawer2)
      this.drawer = this.getDrawer2
      this.consultar()
    }
  },
    
  created(){
    console.log("datos usuario", this.getdatosUsuario)

    // this.idusuariosweb = parseInt(this.getdatosUsuario.idusuariosweb)

    this.consultar()
  },

  methods: {
    ...mapActions('oportunidad',['setOportunidad']),
    ...mapActions('controles',['setDrawer2']),


    consultar(){
      // limpiar
      this.actividades= []
      // console.log(this.getdatosUsuario)
      // let id = this.getdatosUsuario.idusuariosweb
      // console.log('consultar', id)
      this.loading = true

      // this.$http.get("v2/actividades.list" ).then(response=>{

      this.idusuariosweb = parseInt(this.getdatosUsuario.idusuariosweb)
        
      let payload = {
        fecha:    this.fecha1,
        fechafin : this.fecha2,
        idusuariosweb: 0,
        idproyecto: 0,
        idcontacto: 0
      }

      console.log("payloadfiltros ", payload)
     

      this.$http.post("v2/con.actividades", payload ).then(response=>{
        console.log("response", response)

        // console.log("response", response)
        if (response.data.error===null){
          this.actividades = response.data.result

          this.actividades.forEach( element => {
            element.hora = element.fecha.substr(11,5)
            element.fecha = element.fecha.substr(0,10)
            
            element.horafin = element.fechafin.substr(11,5)
            element.fechafin = element.fechafin.substr(0,10)

          })
          this.loading = false
          console.log("ACTIVIDADES", this.actividades)
        }
          
      }).catch(error=>{
        	console.log(error)
      })
    },
      
    editar(item){
      console.log("Renglon item", item)
      this.$router.push({name:'actividad', params: {actividad: item}})
    },

    actividad(){
      // console.log("oportunidad seleccionada",opor1)
      // Mandar llamar al Action.
      // this.setOportunidad (opor1)

      this.drawer = !this.drawer
      this.setDrawer2(true)
    }

  }
}

</script>


<style scoped>
.avatar{
  top: -10px;
}
</style>